.wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.contentWrapper {
  display: block;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

.searchWrapper {
  max-width: 1500px;
  margin: 0 auto;
  padding-left: 4vw;
  padding-right: 4vw;
}

.searchBox {
  border: 1px solid #aaa;
  opacity: 0.7;
  transition: opacity 0.2s ease-out;
  transition: background-image 0.2s ease-out;
  padding: 16px 16px 14px 45px;
  background: url(./assets/Search-Icon.png) no-repeat 12px 50%;
  background-size: 24px 24px;
  width: 100%;
  min-height: 20px;
  display: block;
  box-sizing: border-box;
  font-size: 100%;
  font-family: inherit;
  min-height: 50px;
}

.searchBox:focus {
  outline: none;
}

.searchBox::placeholder {
  color: darkgray;
}

.searchWrapper:hover .searchBox::placeholder {
  color: rgb(120, 120, 120);
}

.searchBox:focus::placeholder {
  color: rgb(120, 120, 120);
}

.searchIcon {
  height: 2rem;
  width: 2rem;
  opacity: 0.5;
}

.icon {
  font-size: 42px;
}

.searchBox:focus + .searchIcon {
  opacity: 0.8;
}

.searchWrapper:hover .searchIcon {
  opacity: 0.8;
}

.searchWrapper:has(+ input) {
  background-color: yellowgreen;
}

.searchFocused {
  border: 2px solid black;
}

.catWrapper {
  width: 300px;
  margin: 0 3vw 8vh 0;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.catHeader {
  text-transform: uppercase;
  padding-bottom: 1.5rem;
  margin-top: 0;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 1.3664;
  font-style: normal;
  letter-spacing: 0em;
  font-family: Poppins, sans-serif;
  border-bottom: 1px solid black;
  font-size: 1.6rem;
  font-weight: 500;
}

.catItemListWrapper {
  padding: 0;
  margin: 0;
  list-style: none;
}

.catItem {
  cursor: pointer;
  padding: 0.25rem 0;
  margin: 1rem 0 1rem 0;
  width: fit-content;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 85%;
  white-space: initial;
}

.catItem:hover {
  opacity: 0.5;
}

.filtered {
  margin-top: 4vh;
  flex: 1;
}

.titleWrapper {
  text-transform: capitalize;
  margin: 5vh auto 0 auto;
  max-width: 1500px;
  padding-left: 16vw;
  padding-right: 16vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.back {
  padding: 0 1rem 0 0;
  font-size: 2rem;
  cursor: pointer;
}

.categoryWrapper {
  margin: 1rem auto;
  max-width: 1500px;
  padding-left: 4vw;
  padding-right: 4vw;
  list-style: none;
}

.cardItem {
  cursor: pointer;
  margin: 8px 0;
  font-size: calc(0.6vw + 1rem);
  width: fit-content;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 90%;
}

.cardItem:hover {
  opacity: 0.5;
}

.title {
  flex: 1;
  font-weight: 500;
  font-size: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-family: acumin-pro, sans-serif;
}

.cardBody-inner {
  font-size: 2.8rem;
  font-weight: 600;
  margin: 0 auto;
  max-width: 1500px;
  padding-left: 16vw;
  padding-right: 16vw;
  line-height: 1.2992;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pdf {
  background-color: black;
  color: white;
  padding: 1rem;
  display: inline-block;
  text-decoration: none;
}

.cardTitleWrapper {
  margin: 0 auto;
  max-width: 1500px;
  padding-left: 4vw;
  padding-right: 4vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  height: 100px;
  padding-bottom: 1rem;
  font-family: acumin-pro, sans-serif;
}
.cardTitleWrapper:after {
  content: "";
  background: black;
  position: absolute;
  bottom: 0;
  left: 4vw;
  right: 4vw;
  height: 1px;
  width: auto;
}

.cardTitle {
  font-weight: 500;
  font-size: 2.6rem;
  text-transform: uppercase;
  flex: 1;
  font-family: acumin-pro, sans-serif;
}

.cardHeaderWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  height: fit-content;
}

.cardIconWrapper {
  display: flex;
  width: fit-content;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-start;
  height: fit-content;
}

@media (max-width: 1024px) {
  .cardHeaderWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  .cardTitleWrapper {
    align-items: center;
  }
  .iconWrapper p {
    text-align: center;
  }
}

@media (max-width: 767px) {
  .cardTitleWrapper {
    flex-direction: row;
    height: fit-content;
    width: auto;
    align-items: center;
    padding-bottom: 1rem;
  }
  .cardTitle {
    line-height: normal;
    margin: auto;
    font-size: 1.6rem;
  }
  .cardHeaderWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: fit-content;
  }
  .back {
    padding: 0 1rem 0 0;
    font-size: 1.6rem;
  }
  .titleWrapper {
    flex-direction: column;
    height: fit-content;
    width: auto;
    align-items: flex-start;
    padding-bottom: 1rem;
  }
  .audioPlayer {
    margin-top: 1rem;
  }
  .title {
    flex: 1;
    font-weight: 500;
    font-size: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-family: acumin-pro, sans-serif;
  }
  .cardIconWrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
    height: fit-content;
  }
}

.audioWrapper {
  margin: 1rem 2rem;
  display: inline-block;
}

.homeWrapper {
  max-width: 1500px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  padding: 6vh 4vw 6vh 4vw;
}

.searchedTitle {
  font-size: 1.5em;
  line-height: 1.3em;
  display: flex;
  max-width: 1500px;
  cursor: pointer;
  padding: 3vh 0;
  font-weight: 400;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.loadWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -120px;
  margin-left: -60px;
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #4d4e4e; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.infoText {
  margin: 2rem auto;
}

.languageSelectWrapper {
  background-color: #fff;
  margin: 0;
}

.languageSelectWrapper:hover {
  background-color: #fff;
  cursor: pointer;
}

.languageCurrent {
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  border: 1px solid #e0e0e0;
  justify-content: space-around;
  align-items: center;
}

.languageScroll {
  position: absolute;
  width: 100px;
  height: 200px;
  overflow-y: scroll;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-top-width: 0;
  z-index: 1;
}

.languageRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0 5px 0 5px;
  background-color: #fff;
}

.languageRow p {
  padding-left: 0.8rem;
  background-color: #fff;
}

.flagIcon {
  height: 30px;
  width: auto;
}

.chevron {
  height: 15px;
  width: auto;
  transition: all 0.5s;
}

.chevronRotateClockwise {
  transform: rotate(90deg);
}

.uppercase {
  text-transform: uppercase;
}

.toggle-button {
  height: 56px;
  width: 56px;
  margin: 0 2rem 0 2rem;
}

.toggle-button:hover {
  cursor: pointer;
}

.easyRead-icon {
  display: inline;
  height: 56px;
  width: 56px;
}

.bslWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

body {
  font-family: Poppins, sans-serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  font-size: 1rem;
  line-height: 1.8;
  -webkit-font-smoothing: antialiased;
}

.cardBodyText {
  line-height: 1.8;
  font-size: 2.8rem;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0em;
  text-transform: none;
  width: 100%;
  border-top: 1px solid #000;
  margin-top: 0;
  padding: 2rem 0 1rem 0;
  font-family: acumin-pro, sans-serif;
}

.cardBodyText a {
  color: black;
}

.cardBodyText hr {
  margin: 2.5rem 0 -3rem 0;
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-weight: 300;
}
.flashcard-content {
  background: #f1f1f1;
  height: calc(100vh - 500px);
  padding: 30px 60px;
}
.header-wrap {
  height: 130px;
  display: flex;
  justify-content: center;
}
.header-inner {
  width: 1600px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.header-left img {
  max-width: 260px;
}
.header-right {
  display: flex;
  text-transform: uppercase;
}
.header-right a {
  color: #28282a;
  text-decoration: none;
  margin-left: 40px;
}
.header-right a:hover {
  color: #469ce0;
}
.header-right .badge-icon {
  background: #f1f1f1;
  margin-left: 20px;
}

.footer-wrap {
  background: #28282a;
  color: white;
  min-height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.footer-inner {
  width: 1500px;
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 70px 60px 40px 60px;
}
.footer-column {
  width: 33.33%;
  display: flex;
  flex-flow: column wrap;
}
.footer-column div {
  margin-bottom: 20px;
}
.footer-column img {
  max-width: 200px;
}
.footer-column .footer-cm-logo {
  max-width: 260px;
}
.copyright-text {
  color: rgba(255, 255, 255, 0.65);
}
.badge-icon {
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: #28282a;
  font-size: 20px;
}
.footer-title {
  text-transform: uppercase;
}
.footer-menu a {
  color: white;
  text-decoration: none;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 100%;
  white-space: initial;
}
.footer-menu a:hover {
  opacity: 0.7;
}
.desktop {
  display: flex;
  align-items: center;
}
.mobile {
  display: none;
}
@media (max-width: 1024px) {
  .header-wrap {
    height: 100px;
  }
  .header-inner {
    padding: 0 30px;
  }
  .footer-inner {
    padding: 40px 20px 20px 20px;
  }
  .footer-column {
    width: 36%;
  }
  .footer-column:last-child {
    width: 25%;
  }
  .flashcard-content {
    padding: 30px 30px;
  }
}
@media (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .flashcard-content {
    padding: 20px 20px;
  }
  .header-wrap {
    height: 80px;
  }
  .header-inner {
    width: 100%;
    padding: 0 20px;
  }
  .header-left img {
    height: 50px;
  }
  .header-right .badge-icon {
    margin-left: 10px;
  }
  .header-right a {
    color: #28282a;
    text-decoration: none;
    margin-left: 20px;
  }
  .footer-inner {
    flex-flow: column wrap;
  }
  .footer-column,
  .footer-column:last-child {
    width: 100%;
  }
}
.iconWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: normal;
  margin: 1rem 2rem 0rem 2rem;
  width: auto;
}

.iconWrapper:hover {
  cursor: pointer;
}

.easyReadWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: baseline;
}

.prev,
.next {
  cursor: pointer;
  height: min-content;
  padding: 16px;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.twitterIcon {
  color: #1da1f2;
  margin: 11px 0 0 3px;
}

.playbackSpeedIcon:hover {
  cursor: pointer;
}

.notesPageWrapper {
  margin: 0 auto;
  max-width: 1500px;
  height: calc(100% - 10vh);
  padding: 20px;
}

.notesPageInner {
  max-width: 1000px;
  margin: 0 auto;
}

.notesInput {
  background: #f7f8fb;
  border: 2px solid #f0f2f7;
  padding: 20px;
  border-radius: 5px;
  color: #919ab0;
  font-size: 1.3rem;
  font-weight: 500;
  width: calc(100% - 44px);
  height: 40vh;
  margin-bottom: 20px;
}

.notesDropContainer {
  display: flex;
  margin-bottom: 20px;
  max-width: 1000px;
}

.notesDropLeft {
  text-align: right;
  width: 40%;
  line-height: 38px;
  padding-right: 20px;
  font-size: 0.9rem;
}

.notesDropRight {
  width: 60%;
}

.notesDropdown {
  color: #242b43;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: #f0f2f7;
  cursor: pointer;
  user-select: none;
  background: #f7f8fb;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 600;
  width: 100%;
}

.notesButtonWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.notesButton {
  border: 0;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin-bottom: 10px;
  color: #242b43;
  font-size: 1rem;
  font-weight: 600;
}

.notesButtonDisabled {
  background: #e4e6ec;
  color: #bcc1cf;
}

/* Ant's stuff */

body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
  font-weight: 300;
}
/* FLASHCARD SECTION */
​ .fc-content {
  background: red;
  padding-top: 3.3vmax;
  padding-bottom: 3.3vmax;
}
.fc-inner {
  margin: 0 auto;
  max-width: 1500px;
  padding-left: 4vw;
  padding-right: 4vw;
  flex-flow: row wrap;
}
.fc-column {
  width: 25%;
}
.fc-column-inner {
  padding-left: 17px;
  padding-right: 17px;
}
.fc-header {
  text-transform: uppercase;
  padding-bottom: 1.5rem;
  margin-top: 0;
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  height: 60px;
  line-height: 1.3664;
  font-style: normal;
  letter-spacing: 0em;
  font-family: acumin-pro, sans-serif;
  border-bottom: 1px solid #000;
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: capitalize;
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.fc-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.fc-item {
  cursor: pointer;
  padding: 0.25rem 0;
  margin: 1rem 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 100%;
  white-space: initial;
}
​
/* HEADER SECTION */
​
.header-wrap {
  height: 130px;
  display: flex;
  justify-content: center;
}
.header-inner {
  width: 1600px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 0 60px 0 60px;
}
.header-left img {
  max-width: 260px;
}
.header-right {
  display: flex;
  text-transform: uppercase;
}
.header-right a {
  color: #28282a;
  text-decoration: none;
  margin-left: 40px;
}
.header-right a:hover {
  color: #469ce0;
}
.header-right .badge-icon {
  background: #f1f1f1;
  margin-left: 20px;
}
​ .footer-wrap {
  background: #28282a;
  color: white;
  min-height: 100px;
  display: flex;
  justify-content: center;
}
.footer-inner {
  width: 1500px;
  display: flex;
  align-items: flex-start;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 70px 60px 40px 60px;
}
.footer-column {
  width: 33.33%;
  display: flex;
  flex-flow: column wrap;
}
.footer-column div {
  margin-bottom: 20px;
}
.footer-column img {
  max-width: 200px;
}
.footer-column .footer-cm-logo {
  max-width: 260px;
}
.copyright-text {
  color: rgba(255, 255, 255, 0.65);
}
.badge-icon {
  background: white;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  line-height: 40px;
  color: #28282a;
  font-size: 20px;
}
.footer-title {
  text-transform: uppercase;
}
.footer-menu a {
  color: white;
  text-decoration: none;
  background-repeat: repeat-x;
  background-image: linear-gradient(
    to right,
    currentColor 100%,
    currentColor 0
  );
  background-size: 1px 1px;
  background-position: 0 100%;
  white-space: initial;
}
.footer-menu a:hover {
  opacity: 0.7;
}
.desktop {
  display: flex;
}
.mobile {
  display: none;
}
@media (max-width: 1024px) {
  .header-wrap {
    height: 100px;
  }
  .header-inner {
    padding: 0 30px;
  }

  .fc-inner {
    padding-left: 2vw;
    padding-right: 2vw;
  }
  .fc-column {
    width: 33.33%; /* REMOVE IF 4COL IS PREFERRED ON TABLET */
  }
  ​ .footer-inner {
    padding: 40px 20px 20px 20px;
  }
  .footer-column {
    width: 36%;
  }
  .footer-column:last-child {
    width: 25%;
  }
  .flashcard-content {
    padding: 30px 30px;
  }
}
@media (max-width: 767px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }

  .fc-column {
    width: 100%;
  }
  .header-wrap {
    height: 80px;
  }
  .header-inner {
    width: 100%;
    padding: 0 20px;
  }
  .header-left img {
    height: 50px;
  }
  .header-right .badge-icon {
    margin-left: 10px;
  }
  .header-right a {
    color: #28282a;
    text-decoration: none;
    margin-left: 20px;
  }
  .footer-inner {
    flex-flow: column wrap;
  }
  .footer-column,
  .footer-column:last-child {
    width: 100%;
  }
  .easyReadWrapper {
    align-items: center;
  }
  .iconWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: normal;
    margin: 0 0.6rem 0 0.6rem;
    width: auto;
    font-size: 0.8rem;
  }
  .icon {
    font-size: 1.6rem;
  }
  .toggle-button {
    height: 1.6rem;
    width: 1.6rem;
    margin: 0;
  }
  .audioPlayer {
    margin: 0.5rem;
  }
}
